import { Dispatch, PayloadAction, UnknownAction, createSlice } from '@reduxjs/toolkit'
import { MenuItem } from './da'
import { DataController } from '../dataController'
import { ToastMessage } from 'wini-web-components'

interface MenuSimpleResponse {
    data: Array<MenuItem>,
    onLoading?: boolean,
    type?: string
}

const initState: MenuSimpleResponse = {
    data: [],
    onLoading: true
}

export const menuSlice = createSlice({
    name: 'menu',
    initialState: initState,
    reducers: {
        handleActions: (state, action: PayloadAction<any>) => {
            switch (action.payload.type) {
                case 'GETDATA':
                    state.data = action.payload.data
                    break;
                default:
                    break;
            }
            state.onLoading = false
        },
        onFetching: (state) => {
            state.onLoading = true
        },
    },
})

const { handleActions, onFetching } = menuSlice.actions

export default menuSlice.reducer

export class MenuActions {
    static getData = async (dispatch: Dispatch<UnknownAction>) => {
        dispatch(onFetching())
        const _controller = new DataController('Menu')
        const res = await _controller.getAll()
        if (res.code !== 200) return ToastMessage.errors(res.message)
        dispatch(handleActions({
            type: 'GETDATA',
            data: res.data,
        }))
    }
}