import '../common.css'
import { Text } from 'wini-web-components'
import { useEffect, useState } from 'react'
import { DataController } from '../../module/dataController'
import ConfigApi from '../../da/configApi'
import { NavLink } from 'react-router-dom'
import { CategoryType } from '../../module/category/da'
import { useForm } from 'react-hook-form'

export default function HomePage() {
    const commonCateId = "c2f2b84a4549421d86dc6dfd9e167cc5"
    const methods = useForm({ shouldFocusError: false })
    const [cateData, setCateData] = useState([])
    const [news, setNews] = useState([])
    const [videos, setVideos] = useState([])
    const [commonNews, setCommonNews] = useState([])

    useEffect(() => {
        const _cateController = new DataController("Category")
        const _newsController = new DataController("News")
        const _bannerController = new DataController("Banner")
        _cateController.aggregateList({ page: 1, size: 4, searchRaw: `@Type:[${CategoryType.newsHome} ${CategoryType.newsHome}]` }).then(res => {
            if (res.code === 200) {
                setCateData(res.data)
                res.data.forEach(e => {
                    _newsController.aggregateList({ page: 1, size: 4, searchRaw: `@CategoryId:{${e.Id}}` }).then(newsRes => {
                        if (newsRes.code === 200) methods.setValue(e.Id, newsRes.data)
                    })
                });
            }
        })
        _newsController.aggregateList({ page: 1, size: 6, searchRaw: `@CategoryId:{${commonCateId}}` }).then(res => {
            if (res.code === 200) setCommonNews(res.data)
        })
        _bannerController.aggregateList({ page: 1, size: 50, searchRaw: `@Type:[1 1]` }).then(res => {
            if (res.code === 200) setVideos(res.data)
        })
    }, [])

    return <div className="col">
        {/* <div className={`project-infor-container row`} style={{ alignItems: "start" }}>
            <div className='col col12 col24-md col24-sm col24-min' style={{ gap: '1rem' }}>
                <Text className='highlight-2'>{project?.Name ?? ""}</Text>
                <Text className='highlight-6'>{project?.Sologan ?? ""}</Text>
            </div>
            <div className='col col12 col24-md col24-sm col24-min'>
                <div className='body-2' dangerouslySetInnerHTML={{ __html: project?.Description ?? "" }} />
            </div>
        </div> */}
        <img alt='ktx gif' src={require('../../assets/KTX.gif')} style={{ width: "100%" }} />
        <div className='ktx-cate-container col'>
            {cateData.map(cate => {
                return <div key={cate.Id} className='col' style={{ gap: "2.4rem" }}>
                    <Text className='highlight-3'>{cate.Name ?? ""}</Text>
                    <div className='col' style={{ gap: "2.4rem" }}>
                        {(methods.watch(cate.Id) ?? []).map((item, i) => <div key={item.Id} className='row ktx-group-container-item' style={{ width: "100%", flexWrap: "wrap", gap: "3.2rem 6.4rem", alignItems: "start" }}>
                            <NavLink to={"/news" + `?id=${item.Id}`} className='col col24-min'><img alt='' style={{ height: "100%", width: "100%", objectFit: "cover" }} src={item?.Img ? item.Img.startsWith("http") ? item.Img : (ConfigApi.imgUrlId + item.Img) : undefined} /></NavLink>
                            <div className='col col24-min' style={{ "--gutter": "3.2rem", gap: "0.8rem", flex: 1 }}>
                                <Text className='heading-5' maxLine={2}>{item.Name}</Text>
                                <div className='body-3 html-text-value' dangerouslySetInnerHTML={{ __html: item.Description ?? "" }} />
                            </div>
                        </div>)}
                    </div>
                </div>
            })}
        </div>
        <div className='ktx-cate-container col' style={{ backgroundColor: "var(--neutral-sub1-background-color)" }}>
            <Text className='highlight-2'>Tin nổi bật</Text>
            <div className="row" style={{ gap: "2.4rem", flexWrap: "wrap", alignItems: "stretch" }}>
                {commonNews.map((item) => {
                    return <div key={item.Id} className={`col col24-min col12-sm col12-md col8-lg col8-xl col8-xxl parent-news-container`}>
                        <NavLink to={"/news" + (item.Link ?? "") + `?id=${item.Id}`} className="row"><img alt="" src={item?.Img ? item.Img.startsWith("http") ? item.Img : (ConfigApi.imgUrlId + item.Img) : undefined} style={{ width: '100%' }} /></NavLink>
                        <div className="col" style={{ gap: "0.8rem" }}>
                            <Text className="heading-6" maxLine={2}>{item.Name}</Text>
                            <div className='body-3 html-text-value' style={{ '--max-line': 4 }} dangerouslySetInnerHTML={{ __html: item.Description ?? "" }} />
                            {/* <Text className="body-3">{item.Description}</Text> */}
                        </div>
                    </div>
                })}
            </div>
        </div>
        {
            videos.map(e => {
                return <iframe key={e.Id} width="100%" style={{ aspectRatio: "16/9" }} src={e.Url} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
            })
        }
    </div >
}