import React, { useEffect, useState } from "react";
import styles from './index.module.css';
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { MenuItem } from "./da";
import { useLocation, useNavigate } from "react-router-dom";
import { Text, Winicon } from "wini-web-components";

export function CardMenu() {
    const { onLoading, data } = useSelector((store: RootState) => store.menu)

    return <div className={`col ${styles['menu-container']}`} style={{ flex: 1, overflow: "hidden auto", padding: "1.6rem" }}>
        {(!onLoading && data.length) ?
            data.filter(e => !e.ParentId).map((e: any) => <ModuleTile key={e.Id} item={e} list={data} />) :
            <div className="col" style={{ gap: "0.4rem" }}>
                {Array.from({ length: 11 }).map((_, i) => <div key={'placeholder-' + i} className="placeholder-animation" style={{ width: "100%", height: "4rem", borderRadius: "0.8rem" }} />)}
            </div>}
    </div>
}

const ModuleTile = (props: { item: MenuItem, list: Array<MenuItem>, space?: number }) => {
    const navigate = useNavigate()
    const [children, setChildren] = useState<Array<MenuItem>>([])
    const [isOpen, setIsOpen] = useState<boolean>(false)

    useEffect(() => {
        if (!props.item.ParentId) {
            const _children = props.list.filter((e: any) => e.ParentId === props.item.Id)
            if (_children.length) {
                if (_children.length !== children.length) setChildren(_children)
                if (!isOpen) {
                    setIsOpen(_children.some(e => e.Link === window.location.pathname))
                }
            }
        }
    }, [props.list])

    return <div className='col' style={{ order: props.item.Sort }}>
        <button className={`row ${styles['module-tile']} ${!children.length && (props.item.Link === "/" ? window.location.pathname === "/" : window.location.pathname.startsWith(props.item.Link ?? "")) ? styles["selected"] : ""}`} style={{ paddingLeft: `${props.space ?? 1.2}rem` }}
            onClick={() => {
                if (props.item.Link) {
                    if (props.item.Link.startsWith(window.location.origin)) navigate(props.item.Link)
                    else window.location.assign(props.item.Link)
                } else setIsOpen(!isOpen)
            }}
        >
            {props.item.Icon ? <Winicon src={props.item.Icon as any} size={"2rem"} /> : <div style={{ width: "2rem" }} />}
            <Text className='label-3' style={{ flex: 1 }}>{props.item.Name}</Text>
            {children.length ? <Winicon src={isOpen ? "fill/arrows/down-arrow" : "fill/arrows/up-arrow"} size={'1.4rem'} className={styles[isOpen ? "open" : "close"]} /> : null}
        </button>
        {children.map(e => <ModuleTile key={e.Id} item={e} space={(props.space ?? 1.2) + 0.4} list={props.list} />)}
    </div >
}