import styles from './index.module.css'
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { Button, Text, TextField, ToastMessage, Winicon } from "wini-web-components"
import { CardRecruit } from '../../module/recruit/card'
import { useEffect, useState } from 'react'
import { DataController } from '../../module/dataController'
import { useForm } from 'react-hook-form'
import { ImportFileForm, TextFieldForm } from '../../project-component/component-form'
import { observerResizeHeader } from '../library/library'
import { randomGID } from '../../Utils'
import { BaseDA } from '../../da/baseDA'

export default function RecruitPage() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const recruitId = params.get('id')

    return recruitId ? <RecruitDetail recruitId={recruitId} /> : <RecruitList />
}

function RecruitList() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const methods = useForm()
    const navigate = useNavigate()
    const pages = useSelector((store) => store.menu.data)
    const selectedPage = pages.find(e => e.Link?.replace(window.location.origin, "") === location.pathname)

    useEffect(() => {
        if (params.get('name')) methods.setValue("name", params.get('name'))
    }, [location])

    return <div className="col">
        <div ref={(ref) => {
            if (ref) observerResizeHeader.observe(ref)
        }} style={{ height: "6.4rem", width: "100%" }}>
            <div className={`row ${styles['recruit-header']}`}>
                <div style={{ flex: 1 }} />
                <TextField
                    name={"name"}
                    placeholder='Tìm kiếm'
                    prefix={<Winicon src="outline/development/zoom" size={"1.6rem"} />}
                    onComplete={(ev) => { ev.target.blur() }}
                    register={methods.register("name", {
                        onBlur: (ev) => {
                            if (ev.target.value.trim().length) navigate("" + `?name=${ev.target.value.trim()}`)
                            else navigate("")
                        }
                    })}
                />
            </div>
        </div>
        <div className={`col ${styles['recruit-body']}`}>
            <Text className='heading-5'>{selectedPage?.Name}</Text>
            <CardRecruit />
        </div>
    </div>
}

function RecruitDetail({ recruitId }) {
    const location = useLocation()
    const pages = useSelector((store) => store.menu.data)
    const selectedPage = pages.find(e => e.Link?.replace(window.location.origin, "") === location.pathname)
    const methods = useForm({ shouldFocusError: false, defaultValues: {} })

    const [data, setData] = useState()

    const _onSubmit = async (ev) => {
        console.log("data: ", ev)
        const _controller = new DataController("CV")
        if (typeof ev.CV !== "string") {
            const fileRes = await BaseDA.uploadFiles([ev.CV])
            if (fileRes) ev.CV = fileRes[0].id
        }
        _controller.add([{ Id: randomGID(), ...ev, RecruitId: recruitId }]).then(res => {
            if (res.code === 200) {
                methods.setValue("Name", "")
                methods.setValue("Phone", "")
                methods.setValue("Email", "")
                methods.setValue("CV", "")
                methods.reset()
                ToastMessage.success("Gửi cv thành công")
            } else {
                ToastMessage.errors(res.message)
            }
        })
    }

    useEffect(() => {
        const _controller = new DataController("Recruit")
        _controller.getById(recruitId).then(res => {
            if (res.code !== 200) return ToastMessage.errors(res.message)
            setData(res.data)
        })
    }, [recruitId])

    return <div className="col" style={{ flex: 1 }}>
        <div ref={(ref) => {
            if (ref) observerResizeHeader.observe(ref)
        }} style={{ height: "6.4rem", width: "100%" }}>
            <div className={`row ${styles['recruit-header']}`}>
                <div className='row' style={{ gap: "0.8rem" }}>
                    <Text className='button-text-6' style={{ color: "var(--neutral-text-subtitle-color)" }}>{selectedPage?.Name}/</Text>
                    <Text className='button-text-6' style={{ color: "var(--neutral-text-title-color)" }}>{data?.Name ?? "-"}</Text>
                </div>
                <div style={{ flex: 1 }} />
                <TextField
                    placeholder='Tìm kiếm'
                    prefix={<Winicon src="outline/development/zoom" size={"1.6rem"} />}
                />
            </div>
        </div>
        <div className={`col`} style={{ gap: "2.4rem", padding: "4rem min(8vw, 15.2rem) min(5.2vw, 10rem)" }}>
            <div className='col' style={{ gap: "0.4rem" }}>
                <Text className='heading-5'>{data?.Name ?? "-"}</Text>
                <Text className='subtitle-2'>{data?.Address ?? "-"}</Text>
            </div>
            <div className='col' style={{ gap: "0.8rem", paddingBottom: "1.6rem" }}>
                <Text className='heading-6'>Mô tả vị trí công việc</Text>
                <div className='body-3' dangerouslySetInnerHTML={{ __html: data?.Description ?? "" }} />
            </div>
            <div className='col' style={{ backgroundColor: "var(--neutral-main-background-color)", padding: "3.2rem", borderRadius: "0.8rem", gap: "3.2rem", maxWidth: "648px" }}>
                <Text className='heading-6'>Ứng tuyển vị trí</Text>
                <div className='col' style={{ gap: "2.4rem" }}>
                    <TextFieldForm
                        required
                        label='Họ và tên'
                        name='Name'
                        errors={methods.formState.errors}
                        register={methods.register}
                        textFieldStyle={{ backgroundColor: "var(--neutral-absolute-background-color)" }}
                    />
                    <TextFieldForm
                        required
                        label='Phone'
                        name='Phone'
                        type='number'
                        errors={methods.formState.errors}
                        register={methods.register}
                        textFieldStyle={{ backgroundColor: "var(--neutral-absolute-background-color)" }}
                    />
                    <TextFieldForm
                        required
                        label='Email'
                        name='Email'
                        errors={methods.formState.errors}
                        register={methods.register}
                        textFieldStyle={{ backgroundColor: "var(--neutral-absolute-background-color)" }}
                    />
                    <ImportFileForm
                        required
                        control={methods.control}
                        label='Resume/CV'
                        name='CV'
                        importStyle={{ backgroundColor: "var(--neutral-absolute-background-color)" }}
                        subTitle='CSV, XLS hoặc XLSX, kích thước tệp nhỏ hơn 20MB.'
                        maxSize={20 * 1024 * 1024}
                    />
                    <Button
                        label='Ứng tuyển'
                        style={{ backgroundColor: "var(--primary-main-color)", color: "var(--neutral-absolute-background-color)", padding: "0.9rem 1.6rem", width: "fit-content" }}
                        onClick={methods.handleSubmit(_onSubmit)}
                    />
                </div>
            </div>
        </div>
    </div>
}