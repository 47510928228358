import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { DataController } from "../dataController";
import { Button, Text, ToastMessage, Winicon } from "wini-web-components";
import { Ultis } from "../../Utils";
import { NavLink, useLocation } from "react-router-dom";
import { DocumentType } from "./da";
import ConfigApi from "../../da/configApi";

export function CardDocumentParent() {
    const [data, setData] = useState([])

    useEffect(() => {
        const _controller = new DataController("Document")
        _controller.aggregateList({ page: 1, size: 50, searchRaw: `*`, filter: `APPLY exists(@ParentId) AS __exist FILTER (@__exist == 0)` }).then(res => setData(res.data))
    }, [])

    return <div className="row" style={{ padding: "min(5.2vw, 10rem) min(8vw, 15.2rem)", gap: "2.4rem", flexWrap: "wrap", alignItems: "stretch" }}>
        {data.map((item: any) => {
            return <div key={item.Id} className={`col col24-min col12-sm col12-md col8-lg col8-xl col8-xxl ${styles['parent-doc-container']}`}>
                <NavLink to={(item.Link ?? "") + `?parentid=${item.Id}`} className="row"><img alt="" src={item?.Img ? item.Img.startsWith("http") ? item.Img : (ConfigApi.imgUrlId + item.Img) : undefined} style={{ width: '100%' }} /></NavLink>
                <div className="col" style={{ gap: "0.8rem" }}>
                    <Text className="heading-6" maxLine={2}>{item.Name}</Text>
                    <Text className="body-3">{item.Description}</Text>
                </div>
            </div>
        })}
    </div>
}

export function CardDocumentLaw(props: { parentId: string }) {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const [dataLaw, setDataLaw] = useState<{ data: Array<{ [p: string]: any }>, totalCount?: number }>({ data: [], totalCount: undefined })
    const [dataCircular, setDataCircular] = useState<{ data: Array<{ [p: string]: any }>, totalCount?: number }>({ data: [], totalCount: undefined })
    const [dataDecree, setDataDecree] = useState<{ data: Array<{ [p: string]: any }>, totalCount?: number }>({ data: [], totalCount: undefined })

    const getData = async (type: DocumentType, page?: number) => {
        const _controller = new DataController("Document")
        const res = await _controller.aggregateList({ page: page ?? 1, size: 8, searchRaw: `@ParentId:{${props.parentId}} @Type:[${type} ${type}]${params.get("name")?.length ? ` @Name:*${params.get("name")}*` : ""}` })
        if (res.code !== 200) return ToastMessage.errors(res.message)
        switch (type) {
            case DocumentType.law:
                setDataLaw({ data: page ? [...dataLaw.data, ...res.data] : res.data, totalCount: res.totalCount })
                break;
            case DocumentType.circular:
                setDataCircular({ data: page ? [...dataCircular.data, ...res.data] : res.data, totalCount: res.totalCount })
                break;
            case DocumentType.decree:
                setDataDecree({ data: page ? [...dataDecree.data, ...res.data] : res.data, totalCount: res.totalCount })
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        getData(DocumentType.law)
        getData(DocumentType.circular)
        getData(DocumentType.decree)
    }, [location])

    return <>
        <div className={`col ${styles['list-document-container']}`} style={{ flexWrap: "nowrap" }}>
            <Text className="heading-5">Luật</Text>
            {dataLaw.data.map((item) => {
                return <div key={item.Id} className="col" style={{ gap: "2.4rem" }}>
                    <div className={`col`} style={{ gap: "0.8rem" }}>
                        <NavLink to={item.Link} target="_blank" ><Text className="heading-7" maxLine={2}>{item.Name}</Text></NavLink>
                        <Text className="subtitle-4" maxLine={2}>Ban hành: {Ultis.datetoString(new Date(item.DateStart))}</Text>
                    </div>
                    {dataLaw.totalCount && dataLaw.totalCount > dataLaw.data.length ? <Button
                        label="Xem thêm"
                        onClick={() => { getData(DocumentType.law, dataLaw.data.length / 8 + 1) }}
                        style={{ width: "fit-content", backgroundColor: "transparent", border: "var(--neutral-main-border-color)", padding: "0.9rem 1.6rem" }}
                    /> : null}
                </div>
            })}
            <div className="col" style={{ height: 1, width: "100%", backgroundColor: "var(--neutral-text-body-reverse-color)" }} />
            <Text className="heading-5">Nghị định</Text>
            {dataDecree.data.map((item) => {
                return <div key={item.Id} className="col" style={{ gap: "2.4rem" }}>
                    <div className={`col`} style={{ gap: "0.8rem" }}>
                        <NavLink to={item.Link} target="_blank" ><Text className="heading-7" maxLine={2}>{item.Name}</Text></NavLink>
                        <Text className="subtitle-4" maxLine={2}>Ban hành: {Ultis.datetoString(new Date(item.DateStart))}</Text>
                    </div>
                    {dataDecree.totalCount && dataDecree.totalCount > dataDecree.data.length ? <Button
                        label="Xem thêm"
                        onClick={() => { getData(DocumentType.law, dataDecree.data.length / 8 + 1) }}
                        style={{ width: "fit-content", backgroundColor: "transparent", border: "var(--neutral-main-border-color)", padding: "0.9rem 1.6rem" }}
                    /> : null}
                </div>
            })}
            <div className="col" style={{ height: 1, width: "100%", backgroundColor: "var(--neutral-text-body-reverse-color)" }} />
            <Text className="heading-5">Thông tư</Text>
            {dataCircular.data.map((item) => {
                return <div key={item.Id} className="col" style={{ gap: "2.4rem" }}>
                    <div className={`col`} style={{ gap: "0.8rem" }}>
                        <NavLink to={item.Link} target="_blank" ><Text className="heading-7" maxLine={2}>{item.Name}</Text></NavLink>
                        <Text className="subtitle-4" maxLine={2}>Ban hành: {Ultis.datetoString(new Date(item.DateStart))}</Text>
                    </div>
                    {dataCircular.totalCount && dataCircular.totalCount > dataCircular.data.length ? <Button
                        label="Xem thêm"
                        onClick={() => { getData(DocumentType.law, dataCircular.data.length / 8 + 1) }}
                        style={{ width: "fit-content", backgroundColor: "transparent", border: "var(--neutral-main-border-color)", padding: "0.9rem 1.6rem" }}
                    /> : null}
                </div>
            })}
        </div>
    </>
}

export function CardDocumentKnowledge(props: { parentId: string }) {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const [dataE, setDataE] = useState<{ data: Array<{ [p: string]: any }>, totalCount?: number }>({ data: [], totalCount: undefined })
    // const [dataS, setDataS] = useState<{ data: Array<{ [p: string]: any }>, totalCount?: number }>({ data: [], totalCount: undefined })
    // const [dataG, setDataG] = useState<{ data: Array<{ [p: string]: any }>, totalCount?: number }>({ data: [], totalCount: undefined })

    const getData = async (type: DocumentType, page?: number) => {
        const _controller = new DataController("Document")
        const res = await _controller.aggregateList({ page: page ?? 1, size: 8, searchRaw: `@ParentId:{${props.parentId}} @Type:[${type} ${type}]${params.get("name")?.length ? ` @Name:*${params.get("name")}*` : ""}` })
        if (res.code !== 200) return ToastMessage.errors(res.message)
        switch (type) {
            case DocumentType.groupE:
                setDataE({ data: page ? [...dataE.data, ...res.data] : res.data, totalCount: res.totalCount })
                break;
            // case DocumentType.groupS:
            //     setDataS({ data: page ? [...dataS.data, ...res.data] : res.data, totalCount: res.totalCount })
            //     break;
            // case DocumentType.groupG:
            //     setDataG({ data: page ? [...dataG.data, ...res.data] : res.data, totalCount: res.totalCount })
            //     break;
            default:
                break;
        }
    }

    useEffect(() => {
        getData(DocumentType.groupE)
        // getData(DocumentType.groupS)
        // getData(DocumentType.groupG)
    }, [location])

    return <>
        <div className={`col ${styles['list-document-container']}`} style={{ flexWrap: "nowrap" }}>
            <Text className="heading-5">Tài liệu “ESG"</Text>
            {dataE.data.map((item) => {
                return <div key={item.Id} className="col" style={{ gap: "2.4rem" }}>
                    <div className={`col`} style={{ gap: "0.8rem" }}>
                        <NavLink to={item.Link} target="_blank" ><Text className="heading-7" maxLine={2}>{item.Name}</Text></NavLink>
                        <Text className="subtitle-4" maxLine={2}>{item.Description}</Text>
                    </div>
                    {dataE.totalCount && dataE.totalCount > dataE.data.length ? <Button
                        label="Xem thêm"
                        onClick={() => { getData(DocumentType.groupE, dataE.data.length / 8 + 1) }}
                        style={{ width: "fit-content", backgroundColor: "transparent", border: "var(--neutral-main-border-color)", padding: "0.9rem 1.6rem" }}
                    /> : null}
                </div>
            })}
            {/* <div className="col" style={{ height: 1, width: "100%", backgroundColor: "var(--neutral-text-body-reverse-color)" }} />
            <Text className="heading-5">Nhóm tài liệu “S"</Text>
            {dataS.data.map((item) => {
                return <div key={item.Id} className="col" style={{ gap: "2.4rem" }}>
                    <div className={`col`} style={{ gap: "0.8rem" }}>
                        <NavLink to={item.Link} target="_blank" ><Text className="heading-7" maxLine={2}>{item.Name}</Text></NavLink>
                        <Text className="subtitle-4" maxLine={2}>{item.Description}</Text>
                    </div>
                    {dataS.totalCount && dataS.totalCount > dataS.data.length ? <Button
                        label="Xem thêm"
                        onClick={() => { getData(DocumentType.groupS, dataS.data.length / 8 + 1) }}
                        style={{ width: "fit-content", backgroundColor: "transparent", border: "var(--neutral-main-border-color)", padding: "0.9rem 1.6rem" }}
                    /> : null}
                </div>
            })}
            <div className="col" style={{ height: 1, width: "100%", backgroundColor: "var(--neutral-text-body-reverse-color)" }} />
            <Text className="heading-5">Nhóm tài liệu “G"</Text>
            {dataG.data.map((item) => {
                return <div key={item.Id} className="col" style={{ gap: "2.4rem" }}>
                    <div className={`col`} style={{ gap: "0.8rem" }}>
                        <NavLink to={item.Link} target="_blank" ><Text className="heading-7" maxLine={2}>{item.Name}</Text></NavLink>
                        <Text className="subtitle-4" maxLine={2}>{item.Description}</Text>
                    </div>
                    {dataG.totalCount && dataG.totalCount > dataG.data.length ? <Button
                        label="Xem thêm"
                        onClick={() => { getData(DocumentType.groupG, dataG.data.length / 8 + 1) }}
                        style={{ width: "fit-content", backgroundColor: "transparent", border: "var(--neutral-main-border-color)", padding: "0.9rem 1.6rem" }}
                    /> : null}
                </div>
            })} */}
        </div>
    </>
}

export function CardDocumentQuestions(props: { parentId: string }) {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const [dataGreen, setDataGreen] = useState<{ data: Array<{ [p: string]: any }>, totalCount?: number }>({ data: [], totalCount: undefined })
    const [dataClean, setDataClean] = useState<{ data: Array<{ [p: string]: any }>, totalCount?: number }>({ data: [], totalCount: undefined })
    const [dataCircle, setDataCircle] = useState<{ data: Array<{ [p: string]: any }>, totalCount?: number }>({ data: [], totalCount: undefined })

    const getData = async (type: DocumentType, page?: number) => {
        const _controller = new DataController("Document")
        const res = await _controller.aggregateList({ page: page ?? 1, size: 8, searchRaw: `@ParentId:{${props.parentId}} @Type:[${type} ${type}]${params.get("name")?.length ? ` @Name:*${params.get("name")}*` : ""}` })
        if (res.code !== 200) return ToastMessage.errors(res.message)
        switch (type) {
            case DocumentType.questionGreen:
                setDataGreen({ data: page ? [...dataGreen.data, ...res.data] : res.data, totalCount: res.totalCount })
                break;
            case DocumentType.questionClean:
                setDataClean({ data: page ? [...dataClean.data, ...res.data] : res.data, totalCount: res.totalCount })
                break;
            case DocumentType.questionCircle:
                setDataCircle({ data: page ? [...dataCircle.data, ...res.data] : res.data, totalCount: res.totalCount })
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        getData(DocumentType.questionGreen)
        getData(DocumentType.questionClean)
        getData(DocumentType.questionCircle)
    }, [location])

    return <>
        <div className={`col ${styles['list-document-container']}`} style={{ gap: "1.2rem" }}>
            <Text className="heading-5">Danh mục Xanh</Text>
            {dataGreen.data.map((item) => {
                return <div key={item.Id} className="col" style={{ gap: "2.4rem", width: "100%" }}>
                    <QuestionTile key={item.Id} item={item} />
                    {dataGreen.totalCount && dataGreen.totalCount > dataGreen.data.length ? <Button
                        label="Xem thêm"
                        onClick={() => { getData(DocumentType.questionGreen, dataGreen.data.length / 8 + 1) }}
                        style={{ width: "fit-content", backgroundColor: "transparent", border: "var(--neutral-main-border-color)", padding: "0.9rem 1.6rem" }}
                    /> : null}
                </div>
            })}
            <div className="col" style={{ height: 1, width: "100%", backgroundColor: "var(--neutral-text-body-reverse-color)" }} />
            <Text className="heading-5">Danh mục Sạch</Text>
            {dataClean.data.map((item) => {
                return <div key={item.Id} className="col" style={{ gap: "2.4rem", width: "100%" }}>
                    <QuestionTile key={item.Id} item={item} />
                    {dataClean.totalCount && dataClean.totalCount > dataClean.data.length ? <Button
                        label="Xem thêm"
                        onClick={() => { getData(DocumentType.questionClean, dataClean.data.length / 8 + 1) }}
                        style={{ width: "fit-content", backgroundColor: "transparent", border: "var(--neutral-main-border-color)", padding: "0.9rem 1.6rem" }}
                    /> : null}
                </div>
            })}
            <div className="col" style={{ height: 1, width: "100%", backgroundColor: "var(--neutral-text-body-reverse-color)" }} />
            <Text className="heading-5">Danh mục Tuần Hoàn</Text>
            {dataCircle.data.map((item) => {
                return <div key={item.Id} className="col" style={{ gap: "2.4rem", width: "100%" }}>
                    <QuestionTile key={item.Id} item={item} />
                    {dataCircle.totalCount && dataCircle.totalCount > dataCircle.data.length ? <Button
                        label="Xem thêm"
                        onClick={() => { getData(DocumentType.questionCircle, dataCircle.data.length / 8 + 1) }}
                        style={{ width: "fit-content", backgroundColor: "transparent", border: "var(--neutral-main-border-color)", padding: "0.9rem 1.6rem" }}
                    /> : null}
                </div>
            })}
        </div>
    </>
}

const QuestionTile = (props: { item: { [p: string]: any } }) => {
    const [isOpen, setOpen] = useState(false)

    return <div className={`row ${styles['document-container-question']}`}>
        <div className="col" style={{ flex: 1, gap: "1.6rem" }}>
            <Text className="heading-6" maxLine={4}>{props.item.Name}</Text>
            {isOpen && <Text className="body-2" maxLine={20}>{props.item.Description}</Text>}
        </div>
        <button type="button" onClick={() => { setOpen(!isOpen) }}><Winicon src={isOpen ? "outline/arrows/circle-arrow-down" : "outline/arrows/circle-arrow-right"} size={"3.2rem"} /></button>
    </div>
}