export enum DocumentType {
    law = 1,
    groupE = 2, // => ESG
    // groupS = 3,
    // groupG = 4,
    decree = 5,
    circular = 6,
    questionGreen = 7,
    questionClean = 8,
    questionCircle = 9,
}