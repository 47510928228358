import { Route, Routes, useParams } from "react-router-dom"
// import { Ultis } from "../Utils"
import styles from './index.module.css'
import { CardMenu } from "../module/menu/card"
import HomePage from "../screen/home/home"
import AboutPage from "../screen/about/about"
import PartnerPage from "../screen/partner/partner"
import ProductPage from "../screen/product/product"
import CommunityPage from "../screen/community/community"
import RecruitPage from "../screen/recruit/recruit"
import PolicyPage from "../screen/policy/policy"
import ContactPage from "../screen/contact/contact"
import { useDispatch, useSelector } from "react-redux"
import { Button, Popup, showPopup, Text, Winicon } from "wini-web-components"
import { ProjectPage } from "../screen/project/project"
import { LibraryPage, LibraryPageKnowledge, LibraryPageLaw, LibraryPageQuestions } from "../screen/library/library"
import { NewsPage } from "../screen/news/news"
import { useEffect, useRef } from "react"
import { CardFooter } from "../module/footer/card"
import { ProjectActions } from "../module/project/reducer"
import { MenuActions } from "../module/menu/reducer"

export const Home = () => {
    const projectData = useSelector((store) => store.project.data)
    const ref = useRef()
    const dispatch = useDispatch()

    useEffect(() => {
        MenuActions.getData(dispatch)
        ProjectActions.getData(dispatch)
    }, [])


    const showMenu = () => {
        showPopup({
            ref: ref,
            hideButtonClose: true,
            clickOverlayClosePopup: true,
            style: { maxHeight: "100dvh", height: "calc(100dvh - 5.6rem)", position: "absolute", left: 0, bottom: 0, borderRadius: 0 },
            content: <div className="col" style={{ backgroundColor: "var(--primary-background)", height: "100%", flex: 1, width: 296 }}>
                <CardMenu />
                {/* {sidebarAction()} */}
            </div>
        })
    }

    const sidebarAction = () => {
        return <div className="col">
            <Button
                label="Đăng nhập"
                prefix={<Winicon src="outline/users/user-c-frame" size={"2rem"} />}
                className={`button-text-1 ${styles['sidebar-action']}`}
                style={{ backgroundColor: "transparent" }}
            />
            <Button
                label="Tiếng Việt"
                prefix={<Winicon src="color/flags/vietnam" size={"2rem"} />}
                className={`button-text-1 ${styles['sidebar-action']}`}
                style={{ backgroundColor: "transparent" }}
            />
        </div>
    }

    return <div className="row main-layout">
        <Popup ref={ref} />
        <div className={`row ${styles['header']} col24-min col24-sm col0-md col0-lg col0-xl col0-xxl`}>
            <button type="button" className={`row ${styles['menu-button']}`} onClick={showMenu}>
                <Winicon src="outline/user interface/menu-8" size={"2.8rem"} />
            </button>
            <div className={`row ${styles['logo']}`} >
                <img alt="" src={require('../assets/Logo.png')} style={{ height: "3.6rem" }} />
                <div className="col" style={{ gap: "0.2rem", alignItems: "end" }}>
                    <Text className="heading-5">{projectData?.Title}</Text>
                    <Text className={styles['logo-subtitle']}>{projectData?.Subtitle ?? "Toả Sáng Tâm Hồn Việt"}</Text>
                </div>
            </div>
        </div>
        <div className={`col ${styles['sidebar']} col0-min col0-sm`}>
            <div className={`row ${styles['logo-container']}`}>
                <img alt="" src={require('../assets/Logo.png')} />
                <div className="col" style={{ gap: "0.2rem", alignItems: "end" }}>
                    <Text className="heading-6">{projectData?.Title}</Text>
                    <Text className={styles['logo-subtitle']}>{projectData?.Subtitle ?? "Toả Sáng Tâm Hồn Việt"}</Text>
                </div>
            </div>
            <CardMenu />
            {/* {sidebarAction()} */}
        </div>
        <div className="col layout-body col24-min col24-sm">
            <Routes>
                <Route path="/:moduleName?/:childModuleName?" element={<MainLayout />} exact />
            </Routes>
            <div style={{ flex: 1 }} />
            <CardFooter />
        </div>
    </div>
}

const MainLayout = () => {
    const { moduleName, childModuleName } = useParams()
    switch (moduleName) {
        case 'home':
            return <HomePage />
        case 'about':
            return <AboutPage />
        case 'partner':
            return <PartnerPage />
        case 'product':
            return <ProductPage />
        case 'project':
            return <ProjectPage />
        case 'library':
            switch (childModuleName) {
                case 'law':
                    return <LibraryPageLaw />
                case 'knowledge':
                    return <LibraryPageKnowledge />
                case 'questions':
                    return <LibraryPageQuestions />
                default:
                    return <LibraryPage />
            }
        case 'community':
            return <CommunityPage />
        case 'recruit':
            return <RecruitPage />
        case 'news':
            return <NewsPage />
        case 'policy':
            return <PolicyPage />
        case 'contact':
            return <ContactPage />
        default:
            return <HomePage />
    }
}