import { Text, ToastMessage } from "wini-web-components";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ConfigApi from "../../da/configApi";
import { ProductType } from "../../module/product/da";
import CardProduct from "../../module/product/card";
import { DataController } from "../../module/dataController";
import { useEffect, useState } from "react";

export function ProjectPage() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const projectId = params.get('id')
    const projectData = useSelector((store) => store.project.data)
    const page = useSelector((store) => store.menu.data.find(e => e.Link?.replace(window.location.origin, "") === location.pathname))

    return projectId ? <ProjectDetail projectId={projectId} /> : <div className="col">
        {page ? <div className={`col page-hero-header`}>
            <img alt="" src={page?.Picture ? page.Picture.startsWith("http") ? page.Picture : (ConfigApi.imgUrlId + page.Picture) : undefined} style={{ width: "100%" }} />
            <div className="col">
                <Text className="highlight-2">{page.Name}</Text>
                <Text className="subtitle-3">{projectData?.Sologan}</Text>
            </div>
        </div> : <></>}
        <CardProduct type={ProductType.project} />
    </div>
}

function ProjectDetail({ projectId, type = ProductType.project }) {
    const location = useLocation()
    const _controller = new DataController("Products")
    const [data, setData] = useState()
    const selectedPage = useSelector((store) => store.menu.data.find(e => e.Link?.replace(window.location.origin, "") === location.pathname))

    useEffect(() => {
        _controller.getById(projectId).then(res => {
            if (res.code !== 200) return ToastMessage.errors(res.message)
            setData(res.data)
        })
    }, [projectId])

    return <div className="col" style={{ flex: 1, }}>
        <div className={`col page-hero-header`} >
            <img alt="" src={data?.Img ? data.Img.startsWith("http") ? data.Img : (ConfigApi.imgUrlId + data.Img) : undefined} style={{ width: "100%", height: "69.5rem", objectFit: "cover" }} />
        </div>
        <div className='col col24-min col24-sm col24-md col24-lg' style={{ flex: 1, width: "100%", gap: '1rem', padding: "min(8vw, 15.2rem) min(5.2vw, 10rem)" }}>
            <div style={{ width: "100%" }}>
                <div className='row' style={{ gap: "0.8rem" }}>
                    {/* <Text className='button-text-6' style={{ color: "var(--neutral-text-subtitle-color)" }}>{pages.find(e => e.Id === selectedPage.ParentId)?.Name ?? "-"}/</Text> */}
                    <Text className='button-text-5' style={{ color: "var(--neutral-text-title-color)" }}>{selectedPage?.Name}</Text>
                </div>
            </div>
            <div className={`col`} style={{ gap: "2.4rem", }}>
                <div className='col' style={{ gap: "0.8rem", paddingBottom: "1.6rem" }}>
                    <Text className='heading-4'>{data?.Name}</Text>
                    {/* <div className="row" >
                        <Text className="subtitle-4" maxLine={1} style={{ paddingRight: "0.4rem" }}>Đăng bởi:</Text>
                        <Text className="subtitle-4" maxLine={1} style={{ paddingRight: "0.4rem" }}>{data?.Author ?? "-"}</Text>
                        <Text className="subtitle-4" style={{ paddingLeft: "0.8rem" }} maxLine={1}>Ngày đăng: {Ultis.datetoString(new Date(data?.DateCreated)) ?? ''}</Text>
                    </div> */}
                    <div className='body-1' dangerouslySetInnerHTML={{ __html: data?.Detail ?? "" }} />
                </div>
            </div>
        </div>
    </div>
}