import { useLocation, NavLink, useNavigate } from 'react-router-dom'
import styles from './index.module.css'
import { useSelector } from 'react-redux'
import { Text, TextField, ToastMessage, Winicon } from 'wini-web-components'
import { BannerSlider, CardCommonNews } from '../../module/news/card'
import { DataController } from '../../module/dataController'
import { useEffect, useState } from 'react'
import { Ultis } from "../../Utils";
import ConfigApi from '../../da/configApi'
import { observerResizeHeader } from '../library/library'
import { NewsType } from './da'
import { CategoryType } from '../../module/category/da'
import { useForm } from 'react-hook-form'

export function NewsPage() {
    const methods = useForm()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const navigate = useNavigate()
    const newId = params.get('id')
    const pages = useSelector((store) => store.menu.data)
    const selectedPage = pages.find(e => e.Link?.replace(window.location.origin, "") === location.pathname)
    const [cates, setCates] = useState([])

    useEffect(() => {
        const _controller = new DataController("Category")
        _controller.aggregateList({ page: 1, size: 2, searchRaw: `@Type:[${CategoryType.news} ${CategoryType.news}]` }).then(res => setCates(res.data))
    }, [])

    useEffect(() => {
        if (params.get('name')) methods.setValue("name", params.get('name'))
    }, [location])


    return newId ? <NewDetail newId={newId} /> : <div className="col" style={{ flex: 1 }}>
        <div ref={(ref) => {
            if (ref) observerResizeHeader.observe(ref)
        }} style={{ height: "6.4rem", width: "100%" }}>
            <div className={`row ${styles['lib-header']}`}>
                <div className='row' style={{ gap: "0.8rem" }}>
                    {/* <Text className='button-text-6' style={{ color: "var(--neutral-text-subtitle-color)" }}>{pages.find(e => e.Id === selectedPage.ParentId)?.Name ?? "-"}/</Text> */}
                    <Text className='button-text-6' style={{ color: "var(--neutral-text-title-color)" }}>{selectedPage?.Name}</Text>
                </div>
                <div style={{ flex: 1 }} />
                <TextField
                    name={"name"}
                    placeholder='Tìm kiếm'
                    prefix={<Winicon src="outline/development/zoom" size={"1.6rem"} />}
                    onComplete={(ev) => { ev.target.blur() }}
                    register={methods.register("name", {
                        onBlur: (ev) => {
                            if (ev.target.value.trim().length) navigate("" + `?name=${ev.target.value.trim()}`)
                            else navigate("")
                        }
                    })}
                />
            </div>
        </div>
        <BannerSlider type={2} />
        <div className={`row ${styles['lib-body']}`} style={{ alignItems: "stretch", gap: "8rem" }}>
            {cates.map((cate) => {
                return <div key={cate.Id} className='col' style={{ flex: 1, gap: "2.4rem" }}>
                    <Text className='heading-5'>{cate.Name}</Text>
                    <div className='col' style={{ gap: "1.6rem" }}>
                        <CardCommonNews key={cate.Id} cateId={cate.Id} />
                    </div>
                </div>
            })}
        </div>
    </div>
}

function NewDetail({ newId }) {
    const location = useLocation()
    const pages = useSelector((store) => store.menu.data)
    const selectedPage = pages.find(e => e.Link?.replace(window.location.origin, "") === location.pathname)
    const [cate, setCate] = useState([])
    const [data, setData] = useState()
    const _controller = new DataController("News")

    const getCate = async () => {
        const res = await _controller.aggregateList({ page: 1, size: 5, searchRaw: `${data.CategoryId ? `-@Id:{${newId}} @CategoryId:{${data.CategoryId}}` : ""}` })
        if (res.code !== 200) return ToastMessage.errors(res.message)
        setCate(res.data)
    }

    useEffect(() => {
        _controller.getById(newId).then(res => {
            if (res.code !== 200) return ToastMessage.errors(res.message)
            setData(res.data)
        })
    }, [newId])
    
    useEffect(() => {
        if(data) getCate()
    }, [data])

    return <div className="col" style={{ flex: 1, }}>
        <div className={`col page-hero-header`} >
            <img alt="" src={data?.Img ? data.Img.startsWith("http") ? data.Img : (ConfigApi.imgUrlId + data.Img) : undefined} style={{ width: "100%", height: "69.5rem", objectFit: "cover" }} />
        </div>
        <div className='row' style={{ gap: "4rem", alignItems: "start", padding: "min(8vw, 15.2rem) min(5.2vw, 10rem)", flexWrap: "wrap" }}>
            <div className='col col24-min col24-sm col24-md col24-lg' style={{ flex: 1, width: "80%", gap: '1rem', "--gutter": "4rem" }}>
                <div style={{ width: "100%" }}>
                    <div className='row' style={{ gap: "0.8rem" }}>
                        {/* <Text className='button-text-6' style={{ color: "var(--neutral-text-subtitle-color)" }}>{pages.find(e => e.Id === selectedPage.ParentId)?.Name ?? "-"}/</Text> */}
                        <Text className='button-text-5' style={{ color: "var(--neutral-text-title-color)" }}>{selectedPage?.Name}</Text>
                    </div>
                </div>
                <div className={`col`} style={{ gap: "2.4rem", }}>
                    <div className='col' style={{ gap: "0.8rem", paddingBottom: "1.6rem" }}>
                        <Text className='heading-4'>{data?.Name}</Text>
                        <div className="row" >
                            <Text className="subtitle-4" maxLine={1} style={{ paddingRight: "0.4rem" }}>Đăng bởi:</Text>
                            <Text className="subtitle-4" maxLine={1} style={{ paddingRight: "0.4rem" }}>{data?.Author ?? "-"}</Text>
                            <Text className="subtitle-4" style={{ paddingLeft: "0.8rem" }} maxLine={1}>Ngày đăng: {Ultis.datetoString(new Date(data?.DateCreated)) ?? ''}</Text>
                        </div>
                        <div className='body-1' dangerouslySetInnerHTML={{ __html: data?.Detail ?? "" }} />
                    </div>
                </div>
            </div>
            <div className="col col24-min col24-sm col24-md col24-lg" style={{ padding: "2.4rem", borderRadius: 8, border: "var(--neutral-main-border-color)", width: "20%", gap: "1.6rem", "--gutter": "4rem" }}>
                <Text className="heading-7">Bài viết liên quan</Text>
                <div className="col" style={{ width: "100%" }}>
                    {cate.map(e => <NavLink key={e.Id} to={"" + `?id=${e.Id}`} target='_blank' className={`label-4 ${styles['relative-news']}`} ><Text maxLine={1}>{e.Name}</Text></NavLink>)}
                </div>
            </div>
        </div>
    </div>
}