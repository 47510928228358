import React, { useState } from "react"
import { useEffect } from "react"
import { DataController } from "../dataController"
import { Text, ToastMessage } from "wini-web-components"
import ConfigApi from "../../da/configApi"
import styles from './index.module.css'
import { ProductType } from "./da"
import { NavLink } from "react-router-dom"

export default function CardProduct({ type = ProductType.product }: { type: ProductType }) {
    const _dataController = new DataController("Products")
    const [data, setData] = useState<Array<{ [p: string]: any }>>([])

    const getData = async () => {
        const res = await _dataController.aggregateList({
            page: 1,
            size: 100,
            searchRaw: `@Status:[${type} ${type}]`
        })
        if (res.code === 200) {
            setData(res.data)
        } else {
            ToastMessage.errors(res.message)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return < >
        {data.filter(e => !e.ParentId).map((item, i) => {
            return <div key={item.Id} className={`col ${styles['card-product-container']}`} style={{ backgroundColor: `var(${i % 2 === 0 ? "--neutral-absolute-background-color" : "--neutral-sub1-background-color"})` }}>
                <div className={`col ${styles['card-product-item']}`} style={{ gap: "6.4rem", width: "100%" }}>
                    <div className="row" style={{ borderBottom: "var(--neutral-main-border-color)", gap: "1.6rem 7dvw", paddingBottom: "4rem", alignItems: "start" }}>
                        <div className="col col10-xxl col10-xl col10-lg col24" ><Text className="highlight-4">{item.Name}</Text></div>
                        <div className="col" style={{ flex: 1, minWidth: "32rem" }}><Text className="highlight-6">{item.Description}</Text></div>
                    </div>
                    <div className="row" style={{ gap: "6.4rem 4rem" }}>
                        {data.filter(e => e.ParentId === item.Id).map((e) => <div key={e.Id} className="row col24 col12-xxl col12-xl" style={{ gap: "2.4rem", alignItems: "start" }}>
                            {type === ProductType.product ? <img src={e.Img ? e.Img.startsWith("http") ? e.Img : (ConfigApi.imgUrlId + e.Img) : undefined} alt="" className="" style={{ width: "16.9rem", borderRadius: "0.8rem" }} /> :
                                <NavLink to={`?id=${e.Id}`}><img src={e.Img ? e.Img.startsWith("http") ? e.Img : (ConfigApi.imgUrlId + e.Img) : undefined} alt="" className="" style={{ width: "16.9rem", borderRadius: "0.8rem" }} /></NavLink>}
                            <div className="col" style={{ flex: 1 }}>
                                <Text className="heading-6">{e.Name}</Text>
                                <Text className="body-3">{e.Description}</Text>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        })}
    </>
}