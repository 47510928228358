import React, { useEffect, useState } from "react";
import { Button, Text } from "wini-web-components";
import styles from "./index.module.css";
import { DataController } from "../dataController";
import ConfigApi from "../../da/configApi";

export function CardContact() {
    const _ctController = new DataController("Contact")
    const [title, setTitle] = useState<{ [p: string]: any }>()
    const [data, setData] = useState<Array<{ [p: string]: any }>>([])
    const getData = async () => {
        const res = await _ctController.getAll()
        if (res.code === 200) {
            setTitle(res.data.find((e: any) => e.Img))
            setData(res.data.filter((e: any) => !e.Img).sort((a: any, b: any) => a.DateCreated - b.DateCreated))
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return data && <div className={`${styles['card-contact-container']} row`}>
        <img alt="" src={title?.Img ? title.Img.startsWith("http") ? title.Img : (ConfigApi.imgUrlId + title.Img) : undefined} className="col col12 col24-md col24-sm col24-min" />
        <div className="col col12 col24-md col24-sm col24-min" style={{ gap: '4rem', flex: 1, alignItems: 'start' }}>
            {title?.Title ? typeof title?.Title === "string" ? <Text className="highlight-2">{title?.Title ?? ''}</Text> : title?.Title : undefined}
            <div className="col" style={{ gap: '2.4rem' }}>
                {data.filter(e => !e.ParentId).map((item) => {
                    return <div key={item.Id} className="col">
                        <Text className="heading-5">{item.Title}</Text>
                        {data.filter(e => e.ParentId === item.Id).map((e) => <div key={e.Id} className="body-3" dangerouslySetInnerHTML={{ __html: e.Description }} />)}
                    </div>
                })}
            </div>
            <Button
                label={"Đăng ký tư vấn"}
                className="button-text-1"
                style={{ height: "5.6rem", padding: "0 3.2rem", color: "var(--neutral-text-stable-color)", backgroundColor: "var(--primary-main-color)" }}
            />
        </div>
    </div>
}