import React, { useEffect, useState } from "react";
import { DataController } from "../dataController";
import { Button, Select1, Text, ToastMessage } from "wini-web-components";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./index.module.css";

export function CardRecruit() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const [data, setData] = useState<{ data: Array<{ [p: string]: any }>, totalCount?: number }>({ data: [], totalCount: undefined })

    const getData = async (page?: number) => {
        const _controller = new DataController("Recruit")
        const res = await _controller.aggregateList({ page: page ?? 1, size: 8, searchRaw: `@Type:[1 1]${params.get("name")?.length ? ` @Name:*${params.get("name")}*` : ""}` })
        if (res.code !== 200) return ToastMessage.errors(res.message)
        setData({ data: page ? [...data.data, ...res.data] : res.data, totalCount: res.totalCount })
    }

    useEffect(() => {
        getData()
    }, [location])

    return <>
        <div className="col" style={{ padding: "0.4rem 0 3.6rem", gap: "1.6rem" }}>
            <Text className="body-3">{data.totalCount} vị trí đang tuyển dụng</Text>
            <div className="row" style={{ gap: "1.2rem" }}>
                <Select1
                    placeholder="Chọn vùng"
                    style={{ width: "14rem" }}
                    options={[{ id: "HN", name: "Hà Nội" }, { id: "HCM", name: "TP Hồ Chí Minh" }]}
                />
                <Select1
                    placeholder="Chọn vị trí"
                    style={{ width: "19.2rem" }}
                    options={[{ id: 1, name: "Nhân viên bán hàng" }, { id: 2, name: "Nhân viên kinh doanh" }, { id: 3, name: "Nhân viên trực tổng đài" }]}
                />
            </div>
        </div>
        <div className={`row ${styles['list-recruit-container']}`}>
            {data.data.map((item) => {
                return <NavLink key={item.Id} to={"/recruit" + `?id=${item.Id}`} className={`col col8 col12-lg col12-md col12-sm col24-min ${styles['recruit-container']}`}>
                    <Text className="heading-7" maxLine={1}>{item.Name}</Text>
                    <Text className="subtitle-4" maxLine={2}>Địa điểm: {item.Address}</Text>
                </NavLink>
            })}
        </div>
        {data.totalCount && data.totalCount > data.data.length ? <Button
            label="Xem thêm"
            onClick={() => { getData(data.data.length / 8 + 1) }}
            style={{ width: "fit-content", backgroundColor: "transparent", border: "var(--neutral-main-border-color)", padding: "0.9rem 1.6rem" }}
        /> : null}
    </>
}