import React, { useEffect, useState } from "react";
import { Text, ToastMessage } from "wini-web-components";
import styles from "./index.module.css";
import { DataController } from "../dataController";
import { NavLink } from "react-router-dom";
import { CustomerType } from "./da";
import ConfigApi from "../../da/configApi";


export function CardCustomer(props: { type: CustomerType }) {
    const [data, setData] = useState<Array<{ [p: string]: any }>>([])

    useEffect(() => {
        const _controller = new DataController("Customer")
        _controller.aggregateList({ page: 1, size: 8, searchRaw: `@Type:${props.type}` }).then((res) => {
            if (res.code !== 200) return ToastMessage.errors(res.message)
            setData(res.data)
        })
    }, [])

    return <div className={`row ${styles['list-customer-container']}`}>
        {data.map((item) => {
            return <div key={item.Id} className={`col col12-min col12-sm col12-md col8-lg col6-xl col6-xxl ${styles['customer-container']}`} customer-type={props.type}>
                <NavLink to={item.Link ?? ""} target="_blank" className="row"><img alt="" src={(item?.Logo ?? item?.Thumbnail) ? (item?.Logo ?? item?.Thumbnail).startsWith("http") ? (item?.Logo ?? item?.Thumbnail) : (ConfigApi.imgUrlId + (item?.Logo ?? item?.Thumbnail)) : undefined} style={{ width: '100%', height: "100%", objectFit: "cover" }} /></NavLink>
                <div className={`col`} style={{ gap: '0.4rem' }} >
                    <Text className="heading-7" maxLine={1}>{item.Name ?? ''}</Text>
                    <Text className="subtitle-4" maxLine={2}>{item.Address ?? ''}</Text>
                </div>
            </div>
        })}
    </div>
}

export function ConsultancyCard() {
    const [data, setData] = useState<{ [p: string]: any }>([])

    useEffect(() => {
        const _controller = new DataController("Footer") // type 1: đăng ký tư vấn
        _controller.getListSimple({ page: 1, size: 1, query: `@Type:[1 1]` }).then((res) => {
            if (res.code !== 200) return ToastMessage.errors(res.message)
            setData(res.data[0])
        })
    }, [])

    return <div className={`row ${styles['consultancy-card-container']}`}>
        <div className="col" style={{ flex: 1, gap: "0.8rem" }}>
            <Text className="heading-4">{data.Name}</Text>
            <Text className="body-3">{data.Description}</Text>
        </div>
        <NavLink
            to={data.Link ?? ""}
            className="button-text-1 row"
            style={{ height: "5.6rem", padding: "0 3.2rem", backgroundColor: "var(--neutral-absolute-background-color)", borderRadius: "0.8rem", color: "var(--neutral-text-subtitle-color)" }}
        >Đăng ký tư vấn</NavLink>
    </div>
}