import { Provider } from 'react-redux';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'wini-web-components';
import { Home } from './router/router';
import { store } from './store';

function App() {
  return <Provider store={store}>
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  </Provider>
}

export default App;
