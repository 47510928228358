import { Dispatch, PayloadAction, UnknownAction, createSlice } from '@reduxjs/toolkit'
import { ToastMessage } from 'wini-web-components'
import { ProjectItem } from './da'
import { BaseDA } from '../../da/baseDA'
import ConfigApi from '../../da/configApi'

interface ProjectSimpleResponse {
    data?: ProjectItem,
    onLoading?: boolean,
    type?: string
}

const initState: ProjectSimpleResponse = {
    data: undefined,
    onLoading: false
}

export const projectSlice = createSlice({
    name: 'project',
    initialState: initState,
    reducers: {
        handleActions: (state, action: PayloadAction<any>) => {
            switch (action.payload.type) {
                case 'GETDATA':
                    state.data = action.payload.data
                    break;
                default:
                    break;
            }
            state.onLoading = false
        },
        onFetching: (state) => {
            state.onLoading = true
        },
    },
})

const { handleActions, onFetching } = projectSlice.actions

export default projectSlice.reducer

export class ProjectActions {
    static getData = async (dispatch: Dispatch<UnknownAction>) => {
        dispatch(onFetching())
        const res = await BaseDA.post(ConfigApi.url + 'wini/getByIds', {
            headers: { module: 'Project' },
            body: { ids: [ConfigApi.pid] }
        })
        if (res.code !== 200) return ToastMessage.errors(res.message)
        dispatch(handleActions({
            type: 'GETDATA',
            data: res.data[0],
        }))
    }
}