import { Text } from "wini-web-components";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CardCustomer, ConsultancyCard } from "../../module/customer/card";
import { CustomerType } from "../../module/customer/da";
import ConfigApi from "../../da/configApi";
export default function PartnerPage() {
    const location = useLocation()
    const page = useSelector((store) => store.menu.data.find(e => e.Link?.replace(window.location.origin, "") === location.pathname))

    return <div className="col" style={{ flex: 1, width: "100%", height: "100%" }}>
        {page ? <div className={`col page-hero-header`}>
            <img alt="" src={page?.Picture ? page.Picture.startsWith("http") ? page.Picture : (ConfigApi.imgUrlId + page.Picture) : undefined} style={{ width: "100%" }} />
            <div className="col">
                <Text className="highlight-2">{page.Name}</Text>
                <Text className="subtitle-3">Kiến tạo xanh toả sáng tâm hồn Việt</Text>
            </div>
        </div> : <></>}
        <CardCustomer type={CustomerType.partner} />
        <ConsultancyCard />
    </div>
}
