import { useEffect, useState } from "react";
import { Text, ToastMessage } from "wini-web-components";
import { CardCategory } from "../../module/category/card";
import { DataController } from "../../module/dataController";
import { CardFooter } from "../../module/footer/card";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styles from './index.module.css';
import ConfigApi from "../../da/configApi";

export default function AboutPage() {
    const projectData = useSelector((store) => store.project.data)
    const location = useLocation()
    const page = useSelector((store) => store.menu.data.find(e => e.Link?.replace(window.location.origin, "") === location.pathname))
    const _dataController = new DataController("Category")
    const _dataNewsController = new DataController("News")
    const [dataCate, setDataCate] = useState({ data: [], total: undefined })
    const [dataNews, setDataNews] = useState({ data: [], total: undefined })
    var cate = "63803a1198db4703a4069a7c33293cbc"
    var cate2 = "5d9646e2c5b242f2919a6463743be4e3"

    const getData = async (page, size) => {
        const resCate = await _dataController.getByListId(
            [cate, cate2]
        )
        const resNews = await _dataNewsController.aggregateList({
            page: page ?? 1,
            size: size ?? 10,
            searchRaw: `@CategoryId:{${cate} | ${cate2}}`,
        })
        if (resCate.code === 200) {
            if (resNews.code === 200) {
                setDataNews({ data: resNews.data, total: resNews.totalCount })
            } else {
                ToastMessage.errors(resNews.message)
            }
            setDataCate({ data: resCate.data, total: resCate.totalCount })
        } else {
            ToastMessage.errors(resCate.message)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return <div className="col" style={{ flex: 1, width: "100%", height: "100%" }}>
        {page ? <div className={`col page-hero-header`}>
            <img alt="" src={page?.Picture ? page.Picture.startsWith("http") ? page.Picture : (ConfigApi.imgUrlId + page.Picture) : undefined} style={{ width: "100%" }} />
            <div className="col">
                <Text className="highlight-2">{page.Name}</Text>
                <Text className="subtitle-3">{projectData?.Sologan}</Text>
            </div>
        </div> : <></>}
        {dataCate.data?.filter(e => e.Id === cate) && dataNews.total != 0 ?
            <div className="col">
                <div className="row" style={{ padding: "min(5.2vw, 10rem) min(8vw, 15.2rem)", gap: "2.4rem", flexWrap: "wrap", alignItems: "stretch" }}>
                    {dataNews.data.filter(e => e.CategoryId === cate).map((item) => {
                        return <CardCategory key={item.Id} id={item.Id} direction="col" data={{ Img: item.Img, Name: item.Name, Description: item.Description }} />
                    })}
                </div>
            </div>
            : <></>}
        {dataCate.data?.filter(e => e.Id === cate2) && dataNews.total != 0 ?
            <div className="col" style={{ backgroundImage: `url(${require("../../assets/background.png")})`, backgroundSize: "cover", backgroundColor: "var(--primary-darker-color)", padding: "min(5.2vw, 10rem) min(8vw, 15.2rem)", height: "70rem", flex: 1 }} >
                <div className={`col ${styles['page-title-header']}`} style={{ paddingBottom: "6.4rem", gap: "1.2rem" }} >
                    <Text className="highlight-2">{dataCate.data.find(e => e.Id === cate2)?.Name ?? ""}</Text>
                    <div className={`subtitle-2 ${styles["description-text"]}`} dangerouslySetInnerHTML={{ __html: dataCate.data.find(e => e.Id === cate2)?.Description ?? "" }} />
                </div>
                <CardCategory direction="row" data={{ Img: dataCate.data.find(e => e.Id === cate2)?.Img, dataList: dataNews.data.filter((e) => e.CategoryId == cate2) }} />
            </div>
            : <></>}
    </div>
}