import { configureStore } from '@reduxjs/toolkit'
import menuReducer from './module/menu/reducer'
import projectReducer from './module/project/reducer'

export const store = configureStore({
    reducer: {
        // account: accountReducer,
        menu: menuReducer,
        project: projectReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch