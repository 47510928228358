import { Text } from "wini-web-components";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ConsultancyCard } from "../../module/customer/card";
import ConfigApi from "../../da/configApi";
import CardProduct from "../../module/product/card";
import { ProductType } from "../../module/product/da";
// Status 1 san pham
// 2 nghien cuu
// 3 trien khai
export default function ProductPage() {
    const location = useLocation()
    const projectData = useSelector((store) => store.project.data)
    const page = useSelector((store) => store.menu.data.find(e => e.Link?.replace(window.location.origin, "") === location.pathname))

    return <div className="col">
        <div className={`col page-hero-header`}>
            <img alt="" src={page?.Picture ? page.Picture.startsWith("http") ? page.Picture : (ConfigApi.imgUrlId + page.Picture) : undefined} style={{ width: "100%" }} />
            <div className="col">
                <Text className="highlight-2">{page?.Name}</Text>
                <Text className="subtitle-3">{projectData?.Sologan}</Text>
            </div>
        </div>
        <CardProduct type={ProductType.product} />
        <ConsultancyCard />
    </div>
}