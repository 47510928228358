import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Text, ToastMessage } from "wini-web-components";
import { DataController } from "../../module/dataController";

export default function PolicyPage() {
    const location = useLocation()
    const page = useSelector((store) => store.menu.data.find(e => e.Link?.replace(window.location.origin, "") === location.pathname))
    const cateId = "6942888ed6d34e698df00161020a6dee"
    // const [poliyData, setPolicyData] = useState()
    const [data, setData] = useState("")

    useEffect(() => {
        // const _cateController = new DataController("Category")
        // _cateController.getById(cateId).then((res) => {
        //     if (res.code !== 200) return ToastMessage.errors(res.message)
        //     setPolicyData(res.data)
        // })
        const _controller = new DataController("News")
        _controller.aggregateList({ page: 1, size: 20, searchRaw: `@CategoryId:{${cateId}}` }).then((res) => {
            if (res.code !== 200) return ToastMessage.errors(res.message)
            setData(res.data[0].Description)
        })
    }, [])

    return <div className="col" style={{ gap: "1.6rem", padding: "6.4rem min(8vw, 15.2rem)" }}>
        <Text className="heading-4">{page?.Name}</Text>
        <div className="body-1" dangerouslySetInnerHTML={{ __html: data }} />
    </div>
}