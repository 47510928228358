import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { DataController } from "../dataController";
import { Button, CustomSlider, Text, ToastMessage, Winicon } from "wini-web-components";
import { Ultis } from "../../Utils";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ConfigApi from "../../da/configApi";

export function CardCommonNews(props: { cateId?: string }) {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const [data, setData] = useState<{ data: Array<any>, totalCount?: number }>({ data: [], totalCount: undefined })

    const getData = async (page?: number) => {
        const _controller = new DataController("News")
        const res = await _controller.aggregateList({ page: page ?? 1, size: 8, searchRaw: `@CategoryId:{${props.cateId}}${params.get("name")?.length ? ` @Name:*${params.get("name")}*` : ""}` })
        if (res.code !== 200) return ToastMessage.errors(res.message)
        setData({ data: page ? [...data.data, ...res.data] : res.data, totalCount: res.totalCount })
    }

    useEffect(() => {
        getData()
    }, [location])

    return <div className={`col ${styles['list-news-container']}`}>
        {data.data.map((item) => {
            return <div key={item.Id} className="col">
                <div className={`row ${styles['news-container']}`} style={{ alignItems: "start", gap: "2.4rem" }}>
                    {item.Img ? <NavLink to={`?id=${item.Id}`} target="_black" className="row col col8 col12-lg col8-md col12-sm col24-min"><img src={item?.Img ? item.Img.startsWith("http") ? item.Img : (ConfigApi.imgUrlId + item.Img) : undefined} alt='' style={{ width: "100%", borderRadius: "8px", height: "16rem", backgroundColor: "var(--primary-background)" }} /></NavLink> : undefined}
                    <div className={`col col24-sm col24-min ${styles["title-header-item"]}`}>
                        <NavLink to={`?id=${item.Id}`} target="_black"><Text className="heading-6" maxLine={2}>{item.Name ?? ''}</Text></NavLink>
                        <div className="row" >
                            <Text className="subtitle-4" maxLine={1} style={{ paddingRight: "0.4rem" }}>Đăng bởi:</Text>
                            <Text className="subtitle-4" maxLine={1} style={{ paddingRight: "0.4rem" }}>{item.Author ?? "-"}</Text>
                            <Text className="subtitle-4" style={{ paddingLeft: "0.8rem" }} maxLine={1}>Ngày đăng: {Ultis.datetoString(new Date(item.DateCreated)) ?? ''}</Text>
                        </div>
                        {item.Description ? typeof item.Description === "string" ? <div className={`body-3 ${styles["description-text"]}`} dangerouslySetInnerHTML={{ __html: item.Description ?? "" }} /> : item.Description : undefined}
                    </div>
                </div>
                {data.totalCount && data.totalCount > data.data.length ? <Button
                    label="Xem thêm"
                    onClick={() => { getData(data.data.length / 8 + 1) }}
                    style={{ width: "fit-content", backgroundColor: "transparent", border: "var(--neutral-main-border-color)", padding: "0.9rem 1.6rem" }}
                /> : null}
            </div>
        })}
    </div>
}

export function BannerSlider(props: { type: number }) {
    const [data, setData] = useState<{ data: Array<any>, totalCount?: number }>({ data: [], totalCount: undefined })
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const cateId = params.get("cateid")
    const [index, setIndex] = useState(0)
    const navigate = useNavigate()

    const getData = async (page?: number) => {
        const _controller = new DataController("Banner")
        const res = await _controller.aggregateList({ page: page ?? 1, size: 10, searchRaw: `@Type:[${props.type} ${props.type}]` })
        if (res.code !== 200) return ToastMessage.errors(res.message)
        setData({ data: page ? [...data.data, ...res.data] : res.data, totalCount: res.totalCount })
    }

    useEffect(() => {
        getData()
    }, [location])

    return <div style={{ backgroundColor: "var(--primary-background)", height: "69.5rem", position: "relative" }}>
        <CustomSlider autoPlay buttons={false} onChage={setIndex} duration={2000} style={{ height: "69.5rem", position: "relative", zIndex: 1 }}>
            {data.data.map(item => <NavLink key={item.Id} to={item.url ?? ""} className="row"><img src={item?.Img ? item.Img.startsWith("http") ? item.Img : (ConfigApi.imgUrlId + item.Img) : undefined} alt='' style={{ width: "100%", objectFit: "cover", height: "100%" }} /></NavLink>)}
        </CustomSlider>
        <div className="col" style={{ position: "absolute", bottom: 46, left: "min(8vw, 15.2rem)", right: "min(8vw, 15.2rem)", zIndex: 2 }}>
            <div className="col" style={{ gap: "2.4rem", }}>
                <div className="row" style={{ gap: "1.2rem" }}>
                    {data.data.map((item, i) => <div key={item.Id} style={{ height: "12px", width: "12px", borderRadius: "50%", backgroundColor: i === index ? "#FFFFFFFF" : "#D9D9D966" }}></div>)}
                </div>
                <Text className="heading-4" style={{ color: "var(--neutral-text-title-reverse-color)" }} maxLine={2}>{data.data[index]?.Name ?? "..."}</Text>
                {data.data.length > 0 && <button type="button" onClick={() => { navigate(`?id=${data.data[index].Id}`) }}>
                    <div className="row" style={{ gap: "0.8rem" }}>
                        <div className="button-text-1">Read more</div>
                        <Winicon src={"outline/arrows/circle-arrow-right"} size={"2rem"} color="var(--primary-darker-color)" />
                    </div>
                </button>}

            </div>
        </div>
    </div>
}