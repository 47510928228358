import { Text } from "wini-web-components";
import styles from "./index.module.css";

import React, { CSSProperties, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import ConfigApi from "../../da/configApi";

interface CardData {
    Name: string,
    Img: string,
    Subtitle?: string,
    DateCreated?: string,
    dataList: [{ Name: string, Description: string }],
    Description: string | ReactNode,
    Link?: string,
}

interface Props {
    id?: string,
    data: CardData,
    direction?: "col" | "row", // row or column,
}

export function CardCategory({ id, data, direction = "col", }: Props) {
    return <div id={id} className={`col col24-min col12-sm col12-md col8-lg col8-xl col8-xxl ${styles[direction]} ${styles["customer-container"]}`}>
        {data?.Img && direction == "col" ? <NavLink to={data?.Link ?? ""} target="_blank" className="row"><img src={data?.Img ? data.Img.startsWith("http") ? data.Img : (ConfigApi.imgUrlId + data.Img) : undefined} alt='' style={{ width: "100%" }} /></NavLink> : undefined}
        <div className={`${direction} ${styles['description-container']}`} >
            {data?.Img && direction == "row" ? <NavLink to={data?.Link ?? ""} target="_blank" className="row col12 col24-md col24-sm col24-min"><img src={data?.Img ? data.Img.startsWith("http") ? data.Img : (ConfigApi.imgUrlId + data.Img) : undefined} alt='' style={{ width: "100%",  borderRadius: "8px", flex: 1 }} /></NavLink> : undefined}
            <div className="col col12 col24-md col24-sm col24-min" style={{ gap: data?.dataList ? '3.2rem' : '0.8rem' }}>
                {data?.dataList ? data.dataList.map((item, index) =>
                    <div key={index} className={`col ${styles["page-title-header"]}`} >
                        <Text className="heading-5" maxLine={1} >{item?.Name ?? ''}</Text>
                        {item?.Description ? typeof item?.Description === "string" ? <div className={`subtitle-2 ${styles["description-text"]}`} dangerouslySetInnerHTML={{ __html: item?.Description ?? "noi dung" }} /> : item?.Description : undefined}
                    </div>
                ) : <div className={`col ${styles["title-header-item"]}`}>
                    <Text className="heading-5" maxLine={1}>{data?.Name ?? ''}</Text>
                    {data?.Description ? typeof data?.Description === "string" ? <div className={`subtitle-2 ${styles["description-text"]}`} dangerouslySetInnerHTML={{ __html: data?.Description ?? "noi dung" }} /> : data?.Description : undefined}
                </div>}
            </div>
        </div>
    </div>
}