import { useLocation, useNavigate } from 'react-router-dom'
import styles from './index.module.css'
import { useSelector } from 'react-redux'
import { Text, TextField, Winicon } from 'wini-web-components'
import { CardDocumentKnowledge, CardDocumentLaw, CardDocumentParent, CardDocumentQuestions } from '../../module/document/card'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import ConfigApi from '../../da/configApi'
import { DataController } from '../../module/dataController'

export const observerResizeHeader = new ResizeObserver((entries) => {
    for (const entry of entries) {
        entry.target.style.setProperty("--header-height", entry.target.offsetWidth + 'px')
    }
})

export function LibraryPage() {
    const projectData = useSelector((store) => store.project.data)
    const location = useLocation()
    const page = useSelector((store) => store.menu.data.find(e => e.Link?.replace(window.location.origin, "") === location.pathname))


    return <div className="col" style={{ flex: 1, width: "100%", height: "100%" }}>
        {page ? <div className={`col page-hero-header`}>
            <img alt="" src={page?.Picture ? page.Picture.startsWith("http") ? page.Picture : (ConfigApi.imgUrlId + page.Picture) : undefined} style={{ width: "100%" }} />
            <div className="col">
                <Text className="highlight-2">{page.Name}</Text>
                <Text className="subtitle-3">{projectData?.Sologan}</Text>
            </div>
        </div> : <></>}
        <CardDocumentParent />
    </div>
}

export function LibraryPageLaw() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const parentId = params.get('parentid')
    const methods = useForm()
    const navigate = useNavigate()
    const pages = useSelector((store) => store.menu.data)
    const selectedPage = pages.find(e => location.pathname.startsWith(e.Link))
    const [parentData, setParentData] = useState()

    useEffect(() => {
        const _controller = new DataController("Document")
        _controller.getById(parentId).then(res => setParentData(res.data))
        if (params.get('name')) methods.setValue("name", params.get('name'))
    }, [location])

    return <div className="col">
        <div ref={(ref) => {
            if (ref) observerResizeHeader.observe(ref)
        }} style={{ height: "6.4rem", width: "100%" }}>
            <div className={`row ${styles['lib-header']}`}>
                <div className='row' style={{ gap: "0.8rem" }}>
                    <Text className='button-text-6' style={{ color: "var(--neutral-text-subtitle-color)" }}>{selectedPage?.Name}/</Text>
                    <Text className='button-text-6' style={{ color: "var(--neutral-text-title-color)" }}>{parentData?.Name}</Text>
                </div>
                <div style={{ flex: 1 }} />
                <TextField
                    name={"name"}
                    placeholder='Tìm kiếm'
                    prefix={<Winicon src="outline/development/zoom" size={"1.6rem"} />}
                    onComplete={(ev) => { ev.target.blur() }}
                    register={methods.register("name", {
                        onBlur: (ev) => {
                            if (ev.target.value.trim().length) navigate("" + `?name=${ev.target.value.trim()}`)
                            else navigate("")
                        }
                    })}
                />
            </div>
        </div>
        {parentData && <div className={`col ${styles['lib-body']}`}>
            {/* <Text className='heading-5'>{parentData.Name}</Text> */}
            <CardDocumentLaw parentId={parentId} />
        </div>}
    </div>
}

export function LibraryPageKnowledge() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const parentId = params.get('parentid')
    const methods = useForm()
    const navigate = useNavigate()
    const pages = useSelector((store) => store.menu.data)
    const selectedPage = pages.find(e => location.pathname.startsWith(e.Link))
    const [parentData, setParentData] = useState()

    useEffect(() => {
        const _controller = new DataController("Document")
        _controller.getById(parentId).then(res => setParentData(res.data))
        if (params.get('name')) methods.setValue("name", params.get('name'))
    }, [location])

    return <div className="col" style={{ flex: 1 }}>
        <div ref={(ref) => {
            if (ref) observerResizeHeader.observe(ref)
        }} style={{ height: "6.4rem", width: "100%" }}>
            <div className={`row ${styles['lib-header']}`}>
                <div className='row' style={{ gap: "0.8rem" }}>
                    <Text className='button-text-6' style={{ color: "var(--neutral-text-subtitle-color)" }}>{selectedPage?.Name}/</Text>
                    <Text className='button-text-6' style={{ color: "var(--neutral-text-title-color)" }}>{parentData?.Name}</Text>
                </div>
                <div style={{ flex: 1 }} />
                <TextField
                    name={"name"}
                    placeholder='Tìm kiếm'
                    prefix={<Winicon src="outline/development/zoom" size={"1.6rem"} />}
                    onComplete={(ev) => { ev.target.blur() }}
                    register={methods.register("name", {
                        onBlur: (ev) => {
                            if (ev.target.value.trim().length) navigate("" + `?name=${ev.target.value.trim()}`)
                            else navigate("")
                        }
                    })}
                />
            </div>
        </div>
        {parentData && <div className={`col ${styles['lib-body']}`}>
            {/* <Text className='heading-5'>{parentData.Name}</Text> */}
            <CardDocumentKnowledge parentId={parentId} />
        </div>}
    </div>
}

export function LibraryPageQuestions() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const parentId = params.get('parentid')
    const methods = useForm()
    const navigate = useNavigate()
    const pages = useSelector((store) => store.menu.data)
    const selectedPage = pages.find(e => location.pathname.startsWith(e.Link))
    const [parentData, setParentData] = useState()

    useEffect(() => {
        const _controller = new DataController("Document")
        _controller.getById(parentId).then(res => setParentData(res.data))
        if (params.get('name')) methods.setValue("name", params.get('name'))
    }, [location])

    return <div className="col" style={{ flex: 1 }}>
        <div ref={(ref) => {
            if (ref) observerResizeHeader.observe(ref)
        }} style={{ height: "6.4rem", width: "100%" }}>
            <div className={`row ${styles['lib-header']}`}>
                <div className='row' style={{ gap: "0.8rem" }}>
                    <Text className='button-text-6' style={{ color: "var(--neutral-text-subtitle-color)" }}>{selectedPage?.Name}/</Text>
                    <Text className='button-text-6' style={{ color: "var(--neutral-text-title-color)" }}>{parentData?.Name}</Text>
                </div>
                <div style={{ flex: 1 }} />
                <TextField
                    name={"name"}
                    placeholder='Tìm kiếm'
                    prefix={<Winicon src="outline/development/zoom" size={"1.6rem"} />}
                    onComplete={(ev) => { ev.target.blur() }}
                    register={methods.register("name", {
                        onBlur: (ev) => {
                            if (ev.target.value.trim().length) navigate("" + `?name=${ev.target.value.trim()}`)
                            else navigate("")
                        }
                    })}
                />
            </div>
        </div>
        {parentData && <div className={`col ${styles['lib-body']}`}>
            {/* <Text className='heading-5'>{parentData.Name}</Text> */}
            <CardDocumentQuestions parentId={parentId} />
        </div>}
    </div>
}