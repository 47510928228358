import React, { useEffect, useState } from "react";
import styles from './index.module.css'
import { Text, ToastMessage } from "wini-web-components";
import { DataController } from "../dataController";
import { NavLink } from "react-router-dom";

export function CardFooter() {
    const [data, setData] = useState<Array<{ [p: string]: any }>>([])

    useEffect(() => {
        const _controller = new DataController("Footer")
        _controller.getAll().then((res) => {
            if (res.code !== 200) return ToastMessage.errors(res.message)
            setData(res.data.filter((e: any) => !e.Type).sort((a: any, b: any) => a.DateCreated - b.DateCreated))
        })
    }, [])

    return <div className={`row ${styles['footer']}`}>
        <div className={`row ${styles['copyright']} col24-sm col24-min`}>
            <img alt="" src={require('../../assets/Logo.png')} />
            <Text className="highlight-4" maxLine={2}>Số hoá nhà vệ sinh Việt Nam <br /> Sạch - Xanh- Tuần Hoàn</Text>
        </div>
        <div className={`row ${styles['link-to']}`} style={{ alignItems: "start" }}>
            {data.filter(e => !e.ParentId).map((item, index) => {
                const children = data.filter(e => e.ParentId === item.Id)
                return <div key={item.Id} className="col" style={{ gap: "2.4rem" }}>
                    <Text className="heading-6" style={{ color: "var(--neutral-text-title-reverse-color)" }}>{item.Name}</Text>
                    {children.length && <div className="col" style={{ gap: "1.2rem" }}>
                        {children.map((e) => <NavLink key={e.Id} to={e.Link} target="_blank" className="label-4" style={{ color: "var(--neutral-text-label-reverse-color)" }}>{e.Name}</NavLink>)}
                    </div>}
                </div>;
            })}
        </div>
    </div>
}